<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="mr-2">السؤال</h4>
        </div>

        <div class="d-flex justify-content-center align-items-center">
          <!-- {{ changeValAnswer }} -->
          <b-form-checkbox
            class="check-box"
            v-model="answerDto.isAnswered"
            name="check-button"
          >
            <!-- @change="toggleVal(questionLessonDto.isAnswerd)" -->
            <b>{{ answerDto.isAnswered ? "مجاب" : "غير مجاب" }}</b>
          </b-form-checkbox>
          <div class="ml-2">
            <span>عدد المجيبين</span>
            <b-badge pill variant="success">
              <span>
                {{ answerDto.answers.length > 0 ? answerDto.answersCount : 0 }}
              </span>
            </b-badge>
          </div>
        </div>
      </div>

      <div class="detailsQuestion p-2">
        <b-row>
          <b-col lg="3" md="12">
            <ek-input-text
              readonly
              name="name"
              label="  اسم الطالب"
              v-model="answerDto.studentName"
            >
            </ek-input-text>
            <ek-input-text
              readonly
              name="name"
              label="  اسم المادة"
              v-model="answerDto.subjectName"
            >
            </ek-input-text>

            <ek-input-text
              readonly
              name="تاريخ السؤال"
              label="  تاريخ السؤال "
              :value="new Date(answerDto.dateCreated).toLocaleString()"
            >
            </ek-input-text>
          </b-col>
          <b-col lg="9" md="12">
            <ek-input-textarea
              readonly
              name="content"
              label="  السؤال  "
              v-model="answerDto.content"
              rows="5"
            >
            </ek-input-textarea>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <div class="w-100 mb-1 rounded">
      <h4 for="question-text">إضافة اجابة</h4>
    </div>
    <b-card>
      <div class="detailsAnswer p-2">
        <ValidationObserver ref="newAnswerForm">
          <b-row>
            <b-col lg="3">
              <ek-input-text
                name="name"
                disabled
                label="اسم المجيب"
                :value="userName"
                placeholder="اسم المجيب"
              >
              </ek-input-text>
            </b-col>
            <b-col lg="12">
              <ek-input-textarea
                label="الإجابة"
                name="name"
                v-model="newAnswer.content"
                placeholder="ادرج الإجابة هنا..."
              ></ek-input-textarea>

              <div>
                <b-row>
                  <b-col cols="3" v-for="(media, i) in newAnswer.medias" :key="i">
                    <ek-input-image
                      name="name"
                      @input="setNewImage($event, i)"
                      class="question-image"
                      @imageDeleted="popImage(i)"
                    >
                      <div
                        class="d-flex align-items-center justify-content-center flex-column"
                      >
                        <div class="d-flex justify-content-end align-items-center">
                          <b-button
                            variant="transperant"
                            class="p-0"
                            @click="deleteAnswer(answerDto.answers, index)"
                          >
                          </b-button>
                        </div>
                        <unicon name="image-plus" height="14px"></unicon>
                        <h5>ادراج صورة</h5>
                      </div>
                    </ek-input-image>
                  </b-col>
                  <b-col cols="1" class="mt-2">
                    <b-button
                      style="height: 50px !important"
                      class="h-100 w-100"
                      variant="outline-primary"
                      @click="AddImg(newAnswer)"
                      :disabled="!newAnswer.medias[newAnswer.medias.length - 1].file"
                    >
                      <unicon fill="gray" name="plus" height="25" width="25"></unicon>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </ValidationObserver>
      </div>

      <template #footer>
        <b-button variant="primary" @click="addAnswer"> حفظ الاجابة</b-button>
      </template>
    </b-card>

    <div class="w-100 mb-1 rounded">
      <h4 for="question-text">الإجابات الحالية</h4>
    </div>
    <b-card v-for="(item, index) in answerDto.answers" :key="index">
      <template #header>
        <div class="d-flex justify-content-between w-100">
          <h4>الجواب {{ index + 1 }}:</h4>

          <div class="d-flex justify-content-end align-items-center">
            <!-- v-if="item.name.trim() === userName.trim()" -->
            <b-button
              variant="transperant"
              class="p-0"
              @click="deleteAnswer(answerDto.answers, index)"
            >
              <svg
                style="cursor: pointer"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
              >
                <path
                  d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                  stroke-width="2"
                  stroke="#EA5455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.875 7.125L7.125 11.875"
                  stroke="#EA5455"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.125 7.125L11.875 11.875"
                  stroke="#EA5455"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </b-button>
          </div>
        </div>
      </template>

      <div class="detailsAnswer p-2">
        <ValidationObserver ref="form">
          <b-row>
            <b-col lg="3">
              <ek-input-text
                name="name"
                label="اسم المجيب"
                v-model="item.answerName"
                placeholder="اسم المجيب"
                disabled
              >
              </ek-input-text>
              <ek-input-text
                disabled
                name="date"
                label="  تاريخ الاجابة "
                :value="new Date(date).toLocaleDateString()"
                placeholder="تاريخ الاجابة"
              ></ek-input-text>
            </b-col>
            <b-col lg="9">
              <div>
                <!-- :disabled="
                                        item.name.trim() !== userName.trim()
                                    " -->
                <ek-input-textarea
                  label="الإجابة"
                  name="name"
                  v-model="item.content"
                  placeholder="ادرج الإجابة هنا..."
                >
                </ek-input-textarea>
              </div>
            </b-col>
            <b-col cols="12">
              <label class="my-1" v-if="item.urls">الصور المرفقة </label>
            </b-col>

            <b-col v-for="(img, index) in item.urls" :key="index" cols="3">
              <img :src="img.url" style="height: 150px" alt="" />
            </b-col>
          </b-row>
        </ValidationObserver>
      </div>
      <div class="d-flex justify-content-start align-item-center">
        <b-button variant="primary mr-2" @click="submit(item)">تعديل</b-button>
        <b-button variant="outline-primary " @click="$router.go(-1)">تراجع</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getUserData } from "@/app/admin/auth/utils";

// import AnswerQuestion from "@admin/lessons/components/addAnswerQuestion/addAnswer/AnswerQuestionFalse.vue";
export default {
  data() {
    return {
      newAnswer: {
        questionId: this.id,
        content: "",
        medias: [
          {
            mediaType: 1,
            file: null,
            thumbFile: null,
          },
        ],
      },
    };
  },
  props: ["id"],
  components: {
    // AnswerQuestion
  },

  computed: {
    ...mapState({
      // questionLessonDto: ({ lessons }) => lessons.questionLessonDto,
      answerDto: ({ questionsAnswerer }) => questionsAnswerer.questionAnswerDashUserDto,
      selected: (state) => state.lessons.selected,
    }),
    userName() {
      return getUserData().name;
    },
    date() {
      return new Date(this.answerDto.dateCreated).toLocaleDateString();
    },
    // changeValAnswer() {
    //     return this.questionLessonDto.isAnswerd ? true : false;
    // }
  },
  methods: {
    ...mapActions([
      "deleteStudentQuestion",
      "modifyStudentQuestion",
      "addNewAnswer",
      "modifyQuestionAnswerDashUser",
    ]),
    deleteImg(id) {
      console.log(id);
    },
    deleteStudent(id) {
      this.deleteStudentQuestion(id);
    },
    AddImg(dto) {
      dto.medias.push({
        mediaType: 1,
        file: null,
        thumbFile: null,
      });
    },
    popImage(i) {
      console.log("poped");
      this.newAnswer.medias = this.newAnswer.medias.filter((el, idx) => {
        return i != idx;
      });
    },
    deleteImage(item, id) {
      if (item.length > 1) {
        let s = item.findIndex((ele, index) => index == id);
        item = item.splice(s, 1);
      }
      console.log(item, id);
    },
    getFile($event, item) {
      // console.log($event);
      item.url = $event.file;
      console.log("in delete file");
    },
    toggleVal(val) {
      if (val) {
        this.answerDto.isAnswerd = false;
      } else this.answerDto.isAnswerd = true;
    },
    submit(item) {
      this.modifyQuestionAnswerDashUser({
        ...item,
        content: item.content,
        id: item.id,
      });
    },
    back() {
      this.$router.go(-1);
      this.$store.commit("RESET_QUESTION_LESSONDTO");
    },
    addAnswer() {
      this.$refs.newAnswerForm.validate().then((v) => {
        if (v) {
          this.addNewAnswer({
            ...this.newAnswer,
            medias: this.newAnswer.medias.filter((m) => !!m.file),
          });
        }
      });
    },
    deleteAnswer(item, id) {
      if (item.length > 1) {
        let s = item.findIndex((ele, index) => index == id);
        item = item.splice(s, 1);
      }
    },
    setNewImage({ file }, i) {
      this.newAnswer.medias[i].file = file;
    },
  },

  created() {
    this.$store.dispatch("getByIdQuestionDashUser", this.id);
  },
  beforeDestroy() {
    this.$store.commit("REASET_QUESTION_DASHUSER_DTO");
    console.log("in destroy");
  },
};
</script>

<style lang="scss">
.check-box {
  margin-top: 5px;
}

.question-image {
  .preview {
    display: flex;
    justify-content: center;

    img {
      height: 100px;
      width: 100px;
      object-fit: contain;
    }
  }
}
</style>
